import { ProduktSlider } from "thalia-molecules/produktlisten/slider";

class ArtikelSlider extends HTMLElement {
    constructor() {
        super();
        this.container = this.querySelector(".tm-produktliste");
        this.produktSlider = new ProduktSlider(this.container);
    }

    connectedCallback() {
        this.produktSlider.init();
    }
}

if (!customElements.get("slider-artikel")) {
    customElements.define("slider-artikel", ArtikelSlider);
}
